import React from "react";
import Album from "../components/Album";
import "./About.css";

const albumImages = [
  { src: process.env.PUBLIC_URL + "/assets/images/img06.jpg", alt: "Image 6" },
  { src: process.env.PUBLIC_URL + "/assets/images/img07.jpg", alt: "Image 7" },
];

function About() {
  return (
    <div className="page-content">
      <h2>About the Seller</h2>
      <h2>Location</h2>
      <h2>Lot</h2>
      <Album images={albumImages} />
    </div>
  );
}

export default About;
