import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Header.css"; // Import the CSS file
import logo from "../assets/images/logo.png";


const Header = () => {
  // State to track user authentication status
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // Function to handle logout
  const handleLogout = () => {
    // Perform logout logic
    // For example, clear authentication token, reset user state, etc.
    setIsLoggedIn(false);
  };

  return (
    <header className="header-container">
      <div className="logo-container">
        <Link to="/">
          <img
            src={logo}
            alt="Logo"
          />
        </Link>
      </div>
      <nav className="nav-container">
        <Link to="/">Home</Link>
        <Link to="/menu">Menu</Link>
        <Link to="/about">About</Link>
        <Link to="/contact">Contact</Link>

        {/* Conditionally render "Login" or "Account" based on isLoggedIn */}
        {isLoggedIn ? (
          <>
            <Link to="/account">Account</Link>
            <button onClick={handleLogout}>Logout</button>
          </>
        ) : (
          <Link to="/login">Login</Link>
        )}
      </nav>
    </header>
  );
};

export default Header;
