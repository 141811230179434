import React, { useState } from "react";
import axios from "axios";

const Register = () => {
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    firstName: "",
    lastName: "",
  });

  const [errors, setErrors] = useState({}); // State to track form validation errors

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.firstName) {
      errors.firstName = "Please enter your first name";
    }
    if (!formData.lastName) {
      errors.lastName = "Please enter your last name";
    }
    if (!formData.email) {
      errors.email = "Please enter your email";
    }
    if (!formData.password) {
      errors.password = "Please enter your password";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0; // Return true if no errors
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return; // Do not proceed if there are validation errors
    }

    try {
      // const apiUrl = "http://localhost:8080/api";
      const apiUrl = "http://54.236.33.205:8080/api";
      // const apiUrl = process.env.REACT_APP_API_URL;

      console.log("apiUrl:", apiUrl);

      // Perform API call to check if the email already exists
      const emailExistsResponse = await axios.get(
        `${apiUrl}/users/email-exists`,
        { email: formData.email }
      );

      console.log("emailExistsResponse:", emailExistsResponse);

      if (
        emailExistsResponse &&
        emailExistsResponse.data &&
        emailExistsResponse.data.exists
      ) {
        console.log("Email already exists");
        setErrors({ email: "Email already exists" });
        return;
      }

      console.log("Email does not exist");

      // If email does not exist, proceed with registration
      // const registrationResponse = await axios.post(
      //   `${apiUrl}/users/register`,
      //   formData
      // );

      // if (registrationResponse && registrationResponse.data) {
      //   console.log("Registration successful:", registrationResponse.data);
      //   // You may want to redirect the user to another page after successful registration
      // } else {
      //   console.error("Registration failed: Unexpected response format");
      // }
    } catch (error) {
      console.error(
        "Registration failed:",
        error.response ? error.response.data : error.message
      );
    }

    try {
      const apiUrl = "http://localhost:8080/api";

      // Perform API call to register the user
      console.log("formData:", formData);

      const registrationResponse = await axios.post(
        `${apiUrl}/users/add`,
        formData
      );

      if (registrationResponse && registrationResponse.data) {
        console.log("Registration successful:", registrationResponse.data);
        // You may want to redirect the user to another page after successful registration
      } else {
        console.error("Registration failed: Unexpected response format");
      }
    } catch (error) {
      console.error(
        "Registration failed:",
        error.response ? error.response.data : error.message
      );
    }
  };

  return (
    <div>
      <h2>Register</h2>
      <form onSubmit={handleSubmit}>
        <label>
          First Name:
          <input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
          />
          {errors.firstName && <p className="error">{errors.firstName}</p>}
        </label>
        <br />
        <label>
          Last Name:
          <input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
          />
          {errors.lastName && <p className="error">{errors.lastName}</p>}
        </label>
        <br />
        <label>
          Email:
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
          {errors.email && <p className="error">{errors.email}</p>}
        </label>
        <br />
        <label>
          Password:
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
          />
          {errors.password && <p className="error">{errors.password}</p>}
        </label>
        <br />
        <button type="submit">Register</button>
      </form>
    </div>
  );
};

export default Register;
