import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <footer>
      <section>
        <h3>Contact Us</h3>
        <address>
          <p>123 Organic Street, Cityville</p>
          <p>Phone: (123) 456-7890</p>
          <p>
            Email:{" "}
            <a href="mailto:info@nourishnook.com">info@nourishnook.com</a>
          </p>
        </address>
      </section>
      <section>
        <h3>Quick Links</h3>
        <nav>
          <ul>
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/menu">Menu</a>
            </li>
            <li>
              <a href="/about">About</a>
            </li>
            <li>
              <a href="/contact">Contact</a>
            </li>
            <li>
              <a href="/account">Account</a>
            </li>
          </ul>
        </nav>
      </section>
      <section>
        <h3>Place an Order</h3>
        <p>
          Explore our menu and place your order online.{" "}
          <a href="/menu">View Menu</a>
        </p>
      </section>
      <section>
        <h3>Follow Us</h3>
        <ul className="social-media-icons">
          <li>
            <a
              href="https://facebook.com/comedorcatering530"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="path-to-facebook-icon.png" alt="Facebook" />
            </a>
          </li>
          {/* Assuming "comedorcatering530" is your Twitter handle */}
          <li>
            <a
              href="https://twitter.com/comedorcatering530"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="path-to-twitter-icon.png" alt="Twitter" />
            </a>
          </li>
        </ul>
      </section>
      <section>
        <h3>Subscribe to Our Newsletter</h3>
        <p>Stay informed about our latest offerings and promotions.</p>
        <form>
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Enter your email"
            required
          />
          <button type="submit">Subscribe</button>
        </form>
      </section>
    </footer>
  );
}

export default Footer;
