import React from "react";

function Home() {
  return (
    <div>
        <h1>Home</h1>
        <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam
            voluptatem, voluptatum, quibusdam, quod voluptate voluptas nemo
            voluptatibus doloremque quas repellendus fugiat. Quisquam voluptatem,
            voluptatum, quibusdam, quod voluptate voluptas nemo voluptatibus
            doloremque quas repellendus fugiat.
        </p>
    </div>
  );
}

export default Home;
